import React from "react"
import star from "../images/icons/star.png"
import "./scss/testimonials.scss"

const Testimonials = ({ title, testimonial, name }) => {
  return (
    <div className="testimonials">
      <div className="test-inner-wrap">
        <h3>{title}</h3>
        <div className="star-wrap">
          <img src={star} alt="" />
          <img src={star} alt="" />
          <img src={star} alt="" />
          <img src={star} alt="" />
          <img src={star} alt="" />
        </div>
        <p>{testimonial}</p>

        <p>
          <strong>{name}</strong>
        </p>
      </div>
    </div>
  )
}

export default Testimonials

import React from "react"
import Layout from "../component/Layout"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Lightbox from "../component/Lightbox"
import ButtonsF from "../component/ButtonsF"
import { Link, animateScroll as scroll } from "react-scroll"
import SEO from "../component/SEO"
import Testimonials from "../component/Testimonials"
import Midweek from "../brochures/MidweekWeddingBrochure.pdf"
import Weekend from "../brochures/WeekendWeddingBrochure.pdf"

const Wedding = ({ data }) => {
  return (
    <Layout>
      <SEO title="Dorlands Weddings" />
      <section className="hero-content weddings">
        <div className="hero-title" id="wedding-hero-title">
          <div className="inner-wrap">
            <h1>Dorlands Weddings</h1>

            <h2>South Wales - Just outside Chepstow</h2>
          </div>
        </div>
        <ButtonsF scroll="dhouse" brochure="brochure" />
      </section>
      <section className="intro-content welcome-dch">
        <div className="slogan">
          <h2>Celebrate Your Wedding With Us</h2>
          <p>
            Whether you are planning a small, intimate occasion or a lively
            festival wedding, Dorlands is the ideal place. The gardens offer the
            freedom for children to roam and play and for romantic with
            beautiful views.
          </p>
          <p>
            Whilst you have your photographs taken you can keep your guests
            entertained by providing them with fun garden games to play during
            the drinks reception. They can get involved in playing Giant Jenga,
            Connect 4 or a game of Chess…whatever garden game you decide to
            bring! An ice breaker for all your guests!
          </p>
          <p>
            Dorlands Barn is exclusively yours, giving you time to share this
            special occasion with your loved ones and an opportunity to make
            magical memories together.
          </p>

          <p>
            We have two large, beautiful properties, sleeping up to 31 people in
            13 bedrooms. We also have a luxury hand built shepherds hut,
            “Franky’s Hideout” that sleeps 2 in a purpose built double bed, the
            ideal romantic space for the bride and groom to escape to!
          </p>
        </div>
      </section>
      <section className="three-pictures-section dorlands_weddings_three_home">
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-two"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          s
          className="picture pic-three"
        ></Link>
      </section>
      <Testimonials
        title="The Perfect Venue"
        testimonial="We want to say a huge thanks to the team at Dorlands, they all worked so hard and helped create the most amazing day. You must go and visit and see how incredible the views really are."
        name="Claire Wood"
      />
      <section className="intro-content weddings-dw">
        <div className="slogan">
          <h2>Weddings Big and Small</h2>
          <p>
            Barn weddings have never been more popular, just outside Bristol,
            Dorlands is the ideal location to celebrate your wedding with all
            your family and friends.
          </p>
          <ul>
            <li>EXCLUSIVE USE OF THE BARN WITH SEATING FOR UP TO 120 GUESTS</li>
            <li>BUILT IN BAR AND LOADS OF SPACE TO DANCE!</li>
            <li>TAILOR THE DAY TO CREATE YOUR DREAM WEDDING</li>
            <li>SITUATED ON 13 ACRES OF PASTURE AND WOODLAND</li>
            <li>INCREDIBLE VIEWS OF USKANY!</li>
            <li>ACCOMMODATION AVAILABLE FOR UP TO 33 GUESTS IF REQUIRED</li>
          </ul>
        </div>
      </section>

      <section className="two-pictures-section dorlands_weddings_two_home">
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-two"
        ></Link>
      </section>
      <section className="intro-content space-dw weddings-info" id="brochures">
        <div className="slogan">
          <h2>More Info</h2>
          <h3>Exclusively Yours</h3>
          <p>
            The whole of Dorlands will be yours to enjoy when you book your
            Wedding with us.
          </p>

          <h3>The Barn Space</h3>
          <p>
            Dorlands Barn is a space that has the flexibility for you to
            decorate and arrange the way you want, to make your unique dream
            wedding a reality. Our barn has a built-in bar area, ladies and
            gents toilets, electric, running water, commercial sinks, hand made
            oak tables, folding chairs and plenthy of room to dance.
          </p>

          <p>
            Please note, Dorlands Barn is not an approved premises for civil
            marriage and civil partnerships. You can, however, arrange for the
            registrar to come for a ceremony.
          </p>

          <h3>The Grounds</h3>
          <p>
            The beautiful grounds mean the sky’s the limit in creating your
            ideal wedding. You could hold a blessing under the trees in the
            woodland, have afternoon drinks in the gardens and drink champagne
            on the terrace as you watch the sun set over the magnificent black
            mountains, and as night falls, pull up a hay bale next to the fire
            and share the laughter and good times with your guests.
          </p>
        </div>

        <div className="brochure" id="brochure">
          <h2>Please Download Our Brochures</h2>
          <ul>
            <li>
              <a href={Midweek} download>
                Midweek
              </a>
            </li>
            <li>
              <a href={Weekend} download>
                Weekend
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className="three-pictures-section dorlands_weddings_three_home_2">
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-one"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-two"
        ></Link>
        <Link
          activeClass="active"
          to="dhouse"
          spy={true}
          smooth={true}
          offset={-140}
          duration={500}
          className="picture pic-three"
        ></Link>
      </section>

      <section className="intro-content perfect-wd">
        <div className="slogan">
          <h2>UNIQUE WEDDING PACKAGES</h2>
          <p>
            The Barn offers the perfect location for your dream wedding
            celebrations. A venue which provides exclusive use of our site and
            easy access to M4 &amp; M5 junctions. The view from the site has to
            be one of the most spectacular in South Wales, overlooking the Usk
            Valley to the Black Mountains and The Brecon Beacons beyond. This
            area is known locally as “Uskany” because of its breathtaking vista
            and tranquil location.
          </p>

          <p>
            Take the opportunity of our wedding packages to extend your
            preparations and celebrations with your family and friends to create
            a memorable few days.
          </p>
        </div>
      </section>

      <section className="galleries" id="dhouse">
        <h2>Weddings @ Dorlands Gallery</h2>
        <Lightbox images={data.wedding.edges} />
      </section>
    </Layout>
  )
}

Wedding.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Wedding

export const galleryQueryOne = graphql`
  query weddingGallery {
    wedding: allFile(filter: { sourceInstanceName: { eq: "weddings" } }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
